import { Supplier } from 'generated/graphql'

export const getSupplierLabelEntity = (supplier?: Supplier | null) => {
  if (!supplier) return ''

  const { ABN, Name, Address1, Suburb, State } = supplier

  if (ABN) {
    return `${ABN} - ${Name}`
  }

  if (!ABN && (Address1 || Suburb || State)) {
    return `${Name} (${Address1} ${Suburb} ${State})`
  }

  return Name ?? ''
}
