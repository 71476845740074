import { useMutation } from '@apollo/react-hooks'
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { GridLayoutToggle } from 'components/common/GridLayoutToggle'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import {
  PlantType,
  SystemConfiguration,
  UpdateSystemConfigurationMutation,
  UpdateSystemConfigurationMutationVariables
} from 'generated/graphql'
import { GET_SYSTEM_CONFIGURATIONS, UPSERT_SYSTEM_CONFIGURATIONS } from '../graphql'
import { Transition } from 'utils/modal'
import { PlantTypesSelector } from 'components/common/PlantTypeSelector'
import { JSONParse } from 'utils/tools'
import { NOAUTOFILL } from 'components/common/AutocompleteInput'
import { GridLayoutInput } from 'components/common/GridLayoutInput'
import { IsActiveToggle } from 'utils/Constants'
import { useSnackbarContext } from 'hooks/useSnackbarContext'

interface FormSystemConfigurationsModalProps {
  handleClose: () => void
  item?: SystemConfiguration
}

export const FormSystemConfigurationsModal = ({ handleClose, item }: FormSystemConfigurationsModalProps) => {
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext()

  const initialValues = {
    ID: item?.ID || '',
    SettingName: item?.SettingName,
    SettingValue: item?.SettingValue || '',
    IsActive: item?.IsActive
  }

  const initialPlantTypes =
    useMemo(() => {
      return JSONParse<PlantType[]>(item?.SettingValue || '[]')
    }, [item]) || []

  const isProjectBoundariesRestrictedPlantTypes = item?.SettingName === 'ProjectBoundariesRestrictedPlantTypes'

  const [loading, setLoading] = useState(false)

  const [plantTypes, setPlantTypes] = useState<PlantType[]>(initialPlantTypes)

  const [upsertModule] = useMutation<UpdateSystemConfigurationMutation, UpdateSystemConfigurationMutationVariables>(
    UPSERT_SYSTEM_CONFIGURATIONS,
    {
      refetchQueries: [{ query: GET_SYSTEM_CONFIGURATIONS }]
    }
  )

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async ({ ID, SettingValue, IsActive }, { resetForm }) => {
      setLoading(true)
      try {
        await upsertModule({
          variables: {
            input: {
              ID: ID || undefined,
              SettingValue: isProjectBoundariesRestrictedPlantTypes
                ? JSON.stringify(
                    plantTypes?.map(plant => {
                      return {
                        ID: plant?.ID,
                        PlantType: plant?.PlantType,
                        Abbreviation: plant?.Abbreviation,
                        ImageFilename: plant?.ImageFilename
                      }
                    }) || '[]'
                  )
                : SettingValue,
              IsActive: !!IsActive
            }
          }
        })
        resetForm()
        handleClose()
        setSuccessMessage('System Configuration has been updated successfully')
        setLoading(false)
      } catch (error) {
        setErrorMessage((error as Error)?.message)
        setLoading(false)
      }
    }
  })
  const { handleSubmit, setValues, values, setFieldValue } = formik
  useEffect(() => {
    setValues({
      ID: item?.ID || '',
      SettingName: item?.SettingName || '',
      SettingValue: item?.SettingValue || '',
      IsActive: item?.IsActive
    })
  }, [item, setValues])

  const handleDeletePlantTypeItem = (plantTypeValue: string) => {
    setPlantTypes(plantTypes?.filter(plant => plant?.PlantType !== plantTypeValue))
  }

  return (
    <>
      <Dialog
        open={!!item}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        onClose={handleClose}
        aria-labelledby="delete-site-diary-dialog-title"
        aria-describedby="delete-site-diary-dialog-description"
      >
        <DialogTitle id="adelete-site-diary-dialog-title">
          {isProjectBoundariesRestrictedPlantTypes
            ? 'Project Boundaries Restricted Plant Types'
            : 'Edit System Configuration'}
        </DialogTitle>
        <DialogContent>
          <FormikProvider value={formik}>
            <Form>
              <Grid container spacing={2}>
                {isProjectBoundariesRestrictedPlantTypes ? (
                  <>
                    <Grid item xs={12}>
                      <PlantTypesSelector
                        onChange={plantType => {
                          setPlantTypes([...(plantType ? [plantType] : []), ...(plantTypes || [])])
                        }}
                        selectedPlantTypes={plantTypes || []}
                        blurOnSelect
                        clearOnBlur
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" flexWrap="wrap" gridGap={8}>
                        {plantTypes?.map(plant => (
                          <Chip
                            key={plant?.ID}
                            label={plant?.PlantType}
                            className="bg-success-color-light"
                            onDelete={() => handleDeletePlantTypeItem(plant?.PlantType)}
                          />
                        ))}
                      </Box>
                    </Grid>
                  </>
                ) : (
                  <>
                    <GridLayoutInput
                      id="Name"
                      name="SettingName"
                      label="Name"
                      variant="filled"
                      autoComplete={NOAUTOFILL}
                      textFieldBreakpoints={{ xs: 12, sm: 12 }}
                      fullWidth
                      disabled
                    />

                    <GridLayoutInput
                      id="Value"
                      name="SettingValue"
                      label="Value"
                      autoComplete={NOAUTOFILL}
                      textFieldBreakpoints={{ xs: 12, sm: 12 }}
                      fullWidth
                    />
                  </>
                )}

                <GridLayoutToggle<IsActiveToggle>
                  toggleBreakpoints={{ xs: 12, sm: 12 }}
                  left={IsActiveToggle.Active}
                  right={IsActiveToggle.InActive}
                  value={values?.IsActive ? IsActiveToggle.Active : IsActiveToggle.InActive}
                  setValue={value => setFieldValue('IsActive', value === IsActiveToggle.Active)}
                />
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="contained">
            Cancel
          </Button>
          <Button
            disabled={loading}
            color="secondary"
            variant="contained"
            onClick={() => handleSubmit()}
            aria-label="button-modal-confirm"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
