import { loader } from 'graphql.macro'
import React from 'react'
import { GetSuppliersQuery, Supplier } from '../../generated/graphql'
import { EntitySelectorWrapperProps } from './EntitySelector'
import { InfiniteSelector } from './InfiniteSelector'
import { getSupplierLabelEntity } from 'utils/renderLabel'

const GET_SUPPLIERS = loader('../../graphql/common/getSuppliers.graphql')

export type SupplierSelectorProps = EntitySelectorWrapperProps<Supplier, GetSuppliersQuery>

const compileData = (data: GetSuppliersQuery) => ({
  data: data.suppliers.items || [],
  pagination: data?.suppliers?.pagination
})

export const SupplierSelector = ({ label = 'Supplier', ...props }: SupplierSelectorProps) => {
  return (
    <InfiniteSelector<Supplier>
      label={label}
      labeller={getSupplierLabelEntity}
      compileData={compileData}
      query={GET_SUPPLIERS}
      {...props}
    />
  )
}
