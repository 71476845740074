import { Dialog, DialogContent, Grid } from '@material-ui/core'
import { Form, FormikProvider, useFormik } from 'formik'
import React, { Dispatch, SetStateAction, useRef } from 'react'

import { useMutation, useQuery } from '@apollo/react-hooks'
import { ApolloQueryResult } from 'apollo-boost'
import classNames from 'classnames'
import {
  CreateNewsArticleMutation,
  CreateNewsArticleMutationVariables,
  GetNewsArticleQuery,
  GetNewsArticleQueryVariables,
  GetPagingNewsArticlesQuery,
  GetPagingNewsArticlesQueryVariables,
  UpdateNewsArticleMutation,
  UpdateNewsArticleMutationVariables
} from 'generated/graphql'
import { useSnackbarContext } from 'hooks/useSnackbarContext'
import { uuidv4 } from 'utils/tools'
import LeftContentCreator from './components/left-content-creator/LeftContentCreator'
import RightPreviewContent from './components/right-preview-content/RightPreviewContent'
import { TEMPLATE_COMPONENTS } from './constants'
import { CREATE_NEWS_ARTICLE, GET_NEWS_ARTICLE, UPDATE_NEWS_ARTICLE } from './graphql'
import { formatCMSCreatorForGraphql, formatCMSCreatorForClient } from './helpers'
import { cmsHeaderContent } from './schemas'
import { CMSCreatorValues, Devices, HeaderBackgroundOptions, PreviewTemplateValue, SettingTheme } from './types'
import { LoadingSkeleton } from 'components/common/LoadingSkeleton'
export interface CMSCreatorProps {
  isShowCMSPage: boolean
  setIsShowCMSPage: Dispatch<SetStateAction<boolean>>
  newsArticleID: string | null
  refetch: (
    variables?: GetPagingNewsArticlesQueryVariables | undefined
  ) => Promise<ApolloQueryResult<GetPagingNewsArticlesQuery>>
}

const initialValues: CMSCreatorValues = {
  title: '',
  content: [
    {
      id: uuidv4(),
      template: PreviewTemplateValue.Header,
      templateComponent: TEMPLATE_COMPONENTS[PreviewTemplateValue.Header - 1],
      images: [],
      imagePreviews: [],
      headerBackgroundOption: HeaderBackgroundOptions.Image,
      sectionName: 'Header',
      isExpanded: true,
      isLayoutExpanded: false,
      textEditors: [
        {
          order: 1,
          description: '',
          template: PreviewTemplateValue.Header
        }
      ]
    }
  ],
  setting: {
    theme: SettingTheme.LightMode,
    device: Devices.Tablet
  }
}

export default function CMSCreator({ isShowCMSPage, setIsShowCMSPage, newsArticleID, refetch }: CMSCreatorProps) {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext()
  const createdID = useRef('')

  const [createNewsArticle, { loading: createLoading }] = useMutation<
    CreateNewsArticleMutation,
    CreateNewsArticleMutationVariables
  >(CREATE_NEWS_ARTICLE, {
    onCompleted({ createNewsArticle }) {
      createdID.current = createNewsArticle.ID
      setSuccessMessage('News Article is created successfully.')
    },
    onError(error) {
      setErrorMessage((error as Error)?.message)
    }
  })

  const [updateNewsArticle, { loading: updateLoading }] = useMutation<
    UpdateNewsArticleMutation,
    UpdateNewsArticleMutationVariables
  >(UPDATE_NEWS_ARTICLE, {
    onCompleted({ updateNewsArticle }) {
      setSuccessMessage(updateNewsArticle)
    },
    onError(error) {
      setErrorMessage((error as Error)?.message)
    }
  })

  const cmsCreatorFormik = useFormik<CMSCreatorValues>({
    enableReinitialize: true,
    initialValues,
    validationSchema: cmsHeaderContent,
    onSubmit(values) {
      newsArticleID || createdID.current
        ? updateNewsArticle({
            variables: { input: { ID: newsArticleID || createdID.current, ...formatCMSCreatorForGraphql(values) } }
          })
        : createNewsArticle({
            variables: { input: formatCMSCreatorForGraphql(values) }
          })
    },
    validateOnBlur: false,
    validateOnChange: false
  })

  const { values, setValues } = cmsCreatorFormik

  const { loading } = useQuery<GetNewsArticleQuery, GetNewsArticleQueryVariables>(GET_NEWS_ARTICLE, {
    variables: {
      id: newsArticleID ?? ''
    },
    onCompleted(data) {
      data?.getNewsArticle && setValues(formatCMSCreatorForClient(data?.getNewsArticle))
    },
    skip: !newsArticleID,

    fetchPolicy: 'cache-and-network'
  })
  // Test to forcing the context CI/CD
  return (
    <Dialog open={isShowCMSPage} fullWidth fullScreen>
      {loading ? (
        <LoadingSkeleton className="max-w-full text-3xl font-semibold" variant="rect">
          News Article Details...
        </LoadingSkeleton>
      ) : (
        <DialogContent className={classNames({ 'p-0': values?.setting?.device === Devices.Desktop })}>
          <FormikProvider value={cmsCreatorFormik}>
            <Form className="w-full h-full">
              <Grid container spacing={values?.setting?.device === Devices.Desktop ? 0 : 2} className="min-h-full">
                <LeftContentCreator
                  newsArticleID={newsArticleID}
                  setIsShowCMSPage={setIsShowCMSPage}
                  disabledButtons={createLoading || updateLoading}
                  refetchList={refetch}
                />
                <RightPreviewContent />
              </Grid>
            </Form>
          </FormikProvider>
        </DialogContent>
      )}
    </Dialog>
  )
}
